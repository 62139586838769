<template>
  <v-card
    class="rounded co-relative"
    color="white"
    flat
    tile
  >
    <v-img
      height="120"
      :src="cover"
      cover
      style="position: absolute; left: 0; top: 0;"
    >
      <div
        class="d-flex justify-end ma-2"
      >
        <pop-menu
          :name="btnMore.name"
          :color="btnMore.color"
          :icon="btnMore.icon"
          :actions="btnMore.actions"
          @action="onAction"
        />
      </div>
    </v-img>
    <div class="co-flex-col">
      <div class="co-w-full co-h80" />
      <div
        class="co-flex-col co-justify-center co-items-center mt-1"
      >
        <v-badge
          avatar
          overlap
          bottom
          :value="userInfo.badge !== ''"
        >
          <template #badge>
            <v-avatar>
              <v-img
                :src="userInfo.badge"
              />
            </v-avatar>
          </template>
          <v-avatar
            size="64"
          >
            <v-img
              :src="userInfo.avatar"
            />
          </v-avatar>
        </v-badge>

        <span class="text-body-1 py-2">{{ userInfo.username }}</span>
      </div>
      <div class="co-flex-row co-justify-around co-items-center px-6 mt-2 mb-5">
        <div class="co-flex-col co-justify-center co-items-center">
          <span class="co-text-md co-font-600">{{ tipAttention.count }}</span>
          <span class="co-text-3xs co-text-gray-500">{{ tipAttention.label }}</span>
        </div>
        <div class="co-h24 co-w2 co-border-l co-border-gray-400" />
        <div class="co-flex-col co-justify-center co-items-center">
          <span class="co-text-md co-font-600">{{ tipFans.count }}</span>
          <span class="co-text-3xs co-text-gray-500">{{ tipFans.label }}</span>
        </div>
        <div class="co-h24 co-w2 co-border-l co-border-gray-400" />
        <div class="co-flex-col co-justify-center co-items-center">
          <span class="co-text-md co-font-600">{{ tipArtical.count }}</span>
          <span class="co-text-3xs co-text-gray-500">{{ tipArtical.label }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'

  export default {
    name: 'SectionIssueCenterUser',
    components: {
      PopMenu: () => import('@/pages/sections/comm/PopMenu.vue')
    },
    data () {
      return {
        cover: app.pic.cover_my,
        userInfo: {},
        tipAttention: {
          label: '关注',
          count: 0
        },
        tipFans: {
          label: '粉丝',
          count: 0
        },
        tipArtical: {
          label: '动态',
          count: 0
        },
        btnMore: {
          name: 'more',
          color: 'white',
          icon: 'mdi-dots-vertical',
          actions: [{
            name: 'cover',
            text: '封面设置',
            icon: 'mdi-image-edit-outline'
          }, {
            name: 'center',
            text: '个人中心',
            icon: 'mdi-account-circle-outline'
          }, {
            name: 'account',
            text: '个人设置',
            icon: 'mdi-account-cog-outline'
          }]
        },
        handlers: {
          cover: this.toSetCover,
          center: this.toUserCenter,
          account: this.toUserAccount
        }
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.getCover()
      this.getExtendInfo()
    },
    methods: {
      onAction (ev) {
        const item = ev.item
        const handler = this.handlers[item.name]
        if (typeof handler === 'function') {
          handler()
        }
      },
      toSetCover () {
        app.emit(app.event.USER_COVER_CHOOSE, this.userInfo)
      },
      toUserCenter () {
        api.page.navigate(this, 'my')
      },
      toUserAccount () {
        api.page.navigate(this, 'info')
      },
      resetCover (ev) {
        if (ev.backgroundImage) {
          this.cover = ev.backgroundImage
          // this.userInfo.backgroundImage = ev.backgroundImage
        }
      },
      getCover () {
        const me = this
        let params = null
        if (me.userInfo.backgroundImage) {
          me.cover = me.userInfo.backgroundImage
        } else {
          const executed = function (res) {
            const item = params.items[0] || {}
            me.cover = item.cover || app.pic.cover_my
          }

          params = web.banner.getParams({
            type: web.comm.BannerTypes.MY_MAJOR,
            state: web.comm.States.PUBLISH,
            executed
          })

          api.httpx.getItems(params)
        }
      },
      getExtendInfo () {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            const data = res.data || {}
            me.tipAttention.count = data.toFansNum || 0
            me.tipFans.count = data.fansNum || 0
            me.tipArtical.count = data.publishContentNum || 0
          }
        }

        user.info.extend({
          executed
        })
      }
    }
  }
</script>
